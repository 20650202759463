body,
html,
canvas {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0%;
    left: 0;
}
:root {
    font-size: 2vmin;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .container {
        position: relative;
    }
    h1 {
        font-family: 'Arvo', serif;
        font-size: 4rem;
    }
    p {
        font-size: 1.5rem;
        font-family: sans-serif;
        font-weight: 600;
        letter-spacing: 0.1rem;
    }
}